/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import TwoSidedLayout from "../components/TwoSidedLayout";
import { useColorScheme } from "@mui/joy";

export default function Main() {
  const { mode } = useColorScheme();
  return (
    <TwoSidedLayout reversed>
      {mode === "dark" && (
        <img height="80" src="./images/qcsku_dark.jpg" alt="" />
      )}
      {mode === "light" && (
        <img height="80" src="./images/qcsku_light.jpg" alt="" />
      )}
      <Typography
        level="h1"
        sx={{
          fontWeight: "xl",
          fontSize: "clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)",
        }}
      >
        Streamline Quality Control for Your Supply Chain
      </Typography>
      <Typography
        textColor="text.secondary"
        sx={{ fontSize: "lg", lineHeight: "lg" }}
      >
        qcsku.com enables brands to collaborate effortlessly with trusted
        agencies on QC inspections. Easily schedule and review inspections while
        managing inventory with seamless integrations to Anvyl, NetSuite, and
        Shopify. Leverage GraphQL API to meet your specific needs.
      </Typography>
      <Link
        sx={{ fontWeight: "lg" }}
        href="https://forms.gle/YPqvsqgVSrto5q5F9"
      >
        Contact Us
      </Link>
      <Button
        disabled
        size="lg"
        endDecorator={<ArrowForward />}
        sx={{ mt: 2, mb: 1 }}
      >
        Launching January 3rd, 2025
      </Button>
    </TwoSidedLayout>
  );
}
